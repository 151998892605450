class LazyLoad {
	constructor(target = null, callback = null) {
		this.elements = target || document.getElementsByClassName('widget-iframe-placeholder');
		this.callback = callback || this.loadIframe;

		try {
			this.initObserver();
		} catch {
			for (const el of this.elements) {
				this.callback(el);
			}
		}
	}

	initObserver() {
		this.observer = new IntersectionObserver(entries => this.handleEntries(entries));

		for (const el of this.elements) {
			if (el.closest('.no-lazy-load')) {
				this.callback(el);
			} else {
				this.observer.observe(el);
			}
		}
	}

	handleEntries(entries) {
		for (const entry of entries) {
			if (entry.isIntersecting) {
				this.callback(entry.target);
				this.observer.unobserve(entry.target);
			}
		}
	}

	loadIframe(el) {
		if (this.observer) {
			this.observer.unobserve(el);
		}

		const iframe = document.createElement('iframe');
		const data = el.dataset;

		iframe.frameBorder = 0;
		iframe.scrolling = 'no';
		iframe.seamless = 'seamless';
		iframe.classList.add('widget-iframe');
		iframe.width = data.width;
		iframe.height = data.height;

		const url = new URL(
			data.src,
			data.src.startsWith('/') ? window.location.origin : null);
		const searchParams = url.searchParams;

		searchParams.append('_is_loaded', 1);

		url.search = searchParams.toString();

		iframe.src = url;

		el.replaceWith(iframe);
	}

	stop() {
		this.observer.disconnect();
	}
}

export default function initLazyLoad(target = null, callback = null) {
	return new LazyLoad(target, callback);
}
