function init() {
	const elements = document.querySelectorAll('[data-scroll]');

	for (const element of elements) {
		element.addEventListener('click', function(e) {
			handleScroll(this.dataset.scroll);
		});
	}
}

function handleScroll(selector) {
	const target = document.querySelector(selector);

	if (!target) {
		return;
	}

	target.scrollIntoView({
		behavior: 'smooth',
	});
}

const Scroll = {
	init,
}

export default Scroll;
