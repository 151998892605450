import activateDidYouMean from './email-suggestion.js';
import reCAPTCHA from './recaptcha.js';

const modal = $("#emailSubscribeModal");
const form = modal.find('form');
const button = form.find(':submit');

let timer = null;
const timerLength = config.affiliate === 'markettamertradeideas' ? 30000 : (config.affiliate === 'evilspeculator' ? 50000 : 20000);

const recaptchaChallenge = modal.length ? new reCAPTCHA(form[0]) : null;

window.showEmailSubscribeModal = function() {
	if (config.user.logged || !modal.is(':hidden')) {
		//console.log("user logged or modal no hidden")
		return ;
	}

	let lastShown;

	try {
		lastShown = localStorage.getItem('emailSubscribeModalLastShown');
	} catch {
		lastShown = false;
	}

	const time = Math.floor(Date.now() / 1000);

	// if (lastShown &&
	// 	Math.abs(time - lastShown) < 86400) {
	// 	//return;
	// }

	var section = $("#main-nav li.active").data('navSection');

	if ($(".modal:visible").length) {
		//console.log("modal visible, try again in",timerLength)
		timer = window.setTimeout(showEmailSubscribeModal, timerLength);
	// } else if (section !== 'search' &&
	// 	![ 'home','stock-prediction', 'technical-analysis', 'stock-compare', 'company-snapshot', 'generic-stocks-table', 'standalone-screen', 'annual-reports' ].includes(window.config.route)) {
	// 	//console.log(section,window.config.route,timerLength)
	// 	timer = window.setTimeout(showEmailSubscribeModal, timerLength);
	} else {
		modal.modal('show');

		try {
			localStorage.setItem(
				'emailSubscribeModalLastShown',
				time);
		} catch {

		}
	}
};

async function submitSignup({ data, button, modal, form }) {
	await ajax('emailSubscribe', data, function(res) {
		if (form.hasClass('login-modal-form')) {
			loginModal.modal('hide');
		}

		if (!window.config.whitelabel) {
			//const coregUrl = data.new_url || config.url.base + 'coreg?email=' + data.email;
			const coregUrl = data.new_url || config.url.base + 'thank-you';
			window.document.location.href = coregUrl
			//window.open(coregUrl);
		}

		config.user.logged = true;
		modal.modal('hide');
		button.button('reset');
	}, function(res) {
		button.button('reset');

		if (res.hasOwnProperty('did_you_mean')) {
			activateDidYouMean(form, data.email, res.did_you_mean);
		}

		recaptchaChallenge.handleResponse(res);
	});
}

if (modal.length) {
	modal.on('show.bs.modal', function() {
		if (recaptchaChallenge.isRequired()) {
			recaptchaChallenge.init();
		}
	});

	modal.on('hidden.bs.modal', function() {
		if (config.user.logged) {
			modal.remove();
		} else {
			// modal.find('form')[0].reset();
			// if (config.affiliate === 'markettamertradeideas') {
			// 	timer = window.setTimeout(showEmailSubscribeModal, timerLength * 4);
			// } else if (config.affiliate === 'evilspeculator') {
			// 	document.cookie = modal.data('cookie');
			// } else {
			// 	timer = window.setTimeout(showEmailSubscribeModal, timerLength * 8);
			// }
		}
	});

	form.on('submit', async function(e) {
		e.preventDefault();
		const data = form.serializeJSON();

		data.currentRoute = config.route;
		data.skip_email_check = form.data('skip_email_check');

		button.button('loading');

		if (window.config.recaptcha.enabled) {
			if (recaptchaChallenge.isInit()) {
				if (!recaptchaChallenge.isValid()) {
					alert('Please complete the challenge. (3)');

					button.button('reset');

					return;
				}
			} else {
				await reCAPTCHA.addToken(data);
			}

			await submitSignup({ data, button, modal, form });
		} else {
			await submitSignup({ data, button, modal, form });
		}
	});

	if (!config.user.logged && showEmailSubscribeModal) {
		timer = window.setTimeout(showEmailSubscribeModal, timerLength);
	}
}
