function initNotifications() {
	$(".notification-item").on("click",function(e) {
		var notif = $(this);

		if (notif.hasClass("read")) {
			return;
		}

		notif.addClass("read")

		$(".notification-badge").html(parseInt($(".notification-badge").html())-1)

		if (parseInt($(".notification-badge").html())==0) {
			$(".notification-badge").hide()
		}

		ajax('notificationOpened', { type:notif.data('type'),type_id: notif.data('type-id')}, function(res) {
			console.log(res)
		});
	})

	$(".notification-mark-all").on('click', function(e) {

		//$(".notification-item").addClass("notification-item-read")

		$(".notification-item").parent().removeAttr("href");
		//$(".notification-item").parent().attr("href","");


		let nots = [];
		for(let i=0; i< $(".notification-item").length; i++){
			nots[i] = {type:$(".notification-item")[i].dataset.type,type_id:$(".notification-item")[i].dataset.typeId}
		}
		ajax('notificationOpened', {type:'multiple',notifications:nots}, function(res) {
			console.log(res)
			$(".notification-badge").hide();
			$(".notification-item").addClass("read")
		});
		//$(".notification-item").trigger('click');
	});
}

async function loadNotifications() {
	const response = await ajax('notifications');

	document.getElementById('notification-placeholder').insertAdjacentHTML('beforebegin', response.html);
	document.getElementById('notification-placeholder').remove();

	initNotifications();
}

document.getElementById('notification-center-icon')?.addEventListener('click', async e => {
	await loadNotifications();
}, { once: true })
