import './public-path.js';
import './scripts.js';
import './extras.js';

const config = window.config;
const ajax = window.ajax;
const $ = window.jQuery;
const _ = window._;

//Function to manipulate AJAX requests.
window.$ajax = {
	totalRequests: 0,
	//Executed at start of ajaxSuccess; response is this
	successStart: function() {

	},
	//Executed at end of ajaxSuccess; response is this
	successEnd: function() {
		if (typeof this.MySQL_logs !== 'undefined') {
			this.MySQL_logs.forEach(function(x) {
				if (!x.match(/^SHOW COLUMNS/)) {
					// console.log(x);
				}
			});
		}
	},
	complete() {
		window.$ajax.totalRequests --;
		// console.log('end', window.$ajax.totalRequests);
	},
	//Process the data, and add any additional data; the data object is this
	data: function() {
		window.$ajax.totalRequests ++;
		// console.log('start', window.$ajax.totalRequests);
		return this;
	}
};

const popupModal = $("#popup-modal");

window.popup = function(text, resolve, reject = null) {
	const popup = popupModal.clone().appendTo('body');

	if (!text) {
		return;
	}

	popup.removeProp('id').find('.modal-body').html($.parseHTML(text.replace(/\n/g, '<br>')));

	if (reject) {
		popup.find('.popup-footer-alert').remove();

		popup.find('.popup-modal-resolve').on('click', function(e) {
			e.preventDefault();

			popup.on('hidden.bs.modal', () => resolve());
			popup.modal('hide');
		});

		popup.find('.popup-modal-reject').on('click', function(e) {
			e.preventDefault();

			popup.on('hidden.bs.modal', () => reject());
			popup.modal('hide');
		});
	} else {
		popup.find('.popup-footer-confirm').remove();

		popup.on('hidden.bs.modal', function() {
			popup.remove();

			if (typeof resolve === 'function') {
				resolve();
			}
		});
	}

	popup.modal('show');
};

window.alert = window.popup;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

window.lastSymbol = null;

import loadPage from './pages.js';

const page = {
	_manageState(data, symbol = null, replace = false) {
		const args = [
			{
				section: data.section,
				navSection: data.navSection,
				symbol: symbol,
				urlData: data.urlData,
			},
			"",
			config.url.base + data.urlData.join('/'),
		];

		if (replace) {
			history.replaceState(... args);
		} else {
			history.pushState(... args);
		}
	},

	'offers/gsg': function() {
		this.pricingModalPage();
	},

	'offers/gsg2': function() {
		this.pricingModalPage();
	},

	'reports': function() {
		loadPage('reports');
	},

	welcome: function() {
		$("#registrationModal").modal('show');
	},

	pricingModalPage: function() {
		const modal = $('.order-modal:first');
		let hiddenFirst = false;

		modal.on('hide.bs.modal', function(e) {
			e.preventDefault();

			if (hiddenFirst === true) {
				window.location = '/';
			}

			hiddenFirst = true;
		});

		$(function() {
			modal.modal('show');
		});
	},
};

window.page = page;

if (config.route != 'main' && $(".content-main-nav").length > 0) {
	loadPage('main');
}

if (typeof page[config.route] === 'function') {
	page[config.route]();
} else {
	loadPage(config.route);
}

const optinForms = document.querySelector('.order-form-trial');

if (optinForms) {
	loadPage('optin');
}

async function initOrderForms() {
	const orderForms = Array.from(document.querySelectorAll('.order-form:not(.order-form-trial)'));

	for (const form of orderForms) {
		const modal = form.closest('.modal');

		if (modal) {
			if (!modal.dataset.autoload && !modal.dataset.autoopen) {
				$(modal).one('show.bs.modal.init', function() {
					loadPage('order', form);
				});

				if (location.hash && location.hash.slice(1) === modal.id) {
					$(modal).modal('show');
					location.hash = '';
				}
			} else {
				await loadPage('order', form);

				if (modal.dataset.autoopen) {
					$(modal).modal('show');
				}
			}
		} else {
			await loadPage('order', form);
		}
	}
}

initOrderForms();

const newsletterFunnelSubscribeModal = $("#newsletterFunnelSubscribeModal");

if (newsletterFunnelSubscribeModal.length) {
	newsletterFunnelSubscribeModal.modal({
		backdrop: 'static',
		keyboard: false
	});

	newsletterFunnelSubscribeModal.on('hidden.bs.modal', function() {
		return false;
	});
}

const freeSearchModal = $("#freeSearchModal");

freeSearchModal.find('form').on('submit', function(e) {
	e.preventDefault();
	var form = $(this),
		data = form.serializeJSON();

	ajax('MTFChillSignup', data, function() {
		window.location.reload();
	});
});

import './includes/login-modal.js';
import './includes/forgot-password-modal.js';
import './includes/registration-modal.js';
import './includes/email-subscribe-modal.js';
import './includes/reset-password-modal.js';
import './includes/notifications.js';

$(".financhill-banner > a, #top-offer > a").on('click', function(e) {
	var $this = $(this);
	ajax('offerClicked', {id: $this.data('offer-id')}, null, null, true);
});

$('.symbol-form-country').on('click', function(e) {
	e.preventDefault();

	const disabled = this.classList.contains('disabled');
	const country = this.dataset.country;

	ajax('toggleCountry', { country, disabled: !disabled ? 1 : 0 }, function(res) {
		(async function() {
			const { autocomplete: symbolAutoComplete } = await import('./includes/symbol-form.js');
			symbolAutoComplete.clearRemoteCache();
		})();

		const countries = document.getElementsByClassName('symbol-form-country');

		for (const country of countries) {
			country.classList.toggle('disabled', !res.countries.includes(country.dataset.country));
		}

		const inputs = document.querySelectorAll('input[name="countries"]');
		const event = new Event('input', { bubbles: true });

		for (const input of inputs) {
			input.value = res.countries.join(',');
			input.dispatchEvent(event);
		}
	});
});

import './includes/analytics.js';

import Toasts from './includes/toasts.js';
Toasts.init();

import Scroll from './includes/scroll.js';
Scroll.init();

import SymbolForm from './includes/symbol-form.js';
SymbolForm.init();

import Countdown from './includes/countdown.js';
// CountdownBar.init();

import scoreWidget from './includes/score-widget.js';
scoreWidget();

//import cookiesBar from './includes/cookies-bar.js';
//cookiesBar();

import marqueeStream from './includes/marquee-stream.js';
marqueeStream();

import initTooltips from './includes/tooltips.js';
initTooltips();

import howItWorks from './includes/how-it-works.js';
howItWorks();

import lazyLoad from './includes/lazy-load.js';
lazyLoad();

import wistiaLazyLoad from './includes/wistia-lazy-load.js';
wistiaLazyLoad();
