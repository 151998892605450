const videos = window.howItWorksVideos;
const modal = $("#howItWorksModal");
const video = modal[0]?.querySelector('video');

function setVideo(key) {
	const data = getActiveVideo();

	if (!data) {
		return;
	}

	video.poster = data.poster;

	const source = document.createElement('source');

	source.src = data.file;
	source.type = 'video/mp4';

	while (video.firstChild) {
		video.removeChild(video.firstChild);
	}

	video.appendChild(source);
}

function getActiveVideo() {
	const key = $(".main-nav-options").find('li.active').data('navSection');
	const data = videos.find(({ id }) => id === key);

	if (!data) {
		return videos.find(({ id }) => id === 'splash');
	}

	return data;
}

export default function initHowItWorks() {
	if (!modal.length) {
		return;
	}

	modal.on('hidden.bs.modal', function() {
		video.pause();
	});

	modal.on('show.bs.modal', function() {
		setVideo();
	});

	modal.on('shown.bs.modal', function() {
		video.play();
	});
}
