const forgotPasswordForm = $(".forgot-password-form");

if (forgotPasswordForm.length) {
	forgotPasswordForm.on('submit', function(e) {
		e.preventDefault();
		var form = $(this),
			btn = form.find('.btn-primary'),
			data = form.serializeJSON();

		if (form.data('submitting') === true) {
			return;
		}

		form.data('submitting', true);
		btn.button('loading').prop('disabled', true);

		data.currentRoute = config.route;

		ajax('forgotPassword', data, function(r) {
			form.next().find(".forgot-password-email").text(r.email);
			form.remove();
		}, function() {
			btn.button('reset').prop('disabled', false);
			form.data('submitting', false);
		});
	});
}

const forgotPasswordModal = $("#forgotPasswordModal");

forgotPasswordModal.on('hidden.bs.modal', function() {
	forgotPasswordModal.find('form')[0].reset();
});
