import activateDidYouMean from './email-suggestion.js';
import reCAPTCHA from './recaptcha.js';

const modal = $("#registrationModal");
const form = modal.find('form');
const button = form.find(':submit');

window.registrationModal = modal;

const recaptchaChallenge = modal.length ? new reCAPTCHA(form[0]) : null;

async function submitSignup({ data, button, modal, form }) {
	await ajax('register', data, async function(res) {
		await gtmEvent('sign_up', { method: 'register_modal' });

		config.user.logged = true;

		modal.trigger('registered');
		button.button('reset');

		window.location = res.redirect;
	}, function(res) {
		button.button('reset');

		if (res.hasOwnProperty('did_you_mean')) {
			activateDidYouMean(form, data.email, res.did_you_mean);
		}

		recaptchaChallenge.handleResponse(res);
	});
}

if (modal.length) {
	modal.on('show.bs.modal', function() {
		if (recaptchaChallenge.isRequired()) {
			recaptchaChallenge.init();
		}
	});

	form.on('submit', async function(e) {
		e.preventDefault();
		const data = form.serializeJSON();

		data.currentRoute = config.route;
		data.skip_email_check = form.data('skip_email_check');

		button.button('loading');

		if (window.config.recaptcha.enabled) {
			if (recaptchaChallenge.isInit()) {
				if (!recaptchaChallenge.isValid()) {
					alert('Please complete the challenge. (3)');

					button.button('reset');

					return;
				}
			} else {
				await reCAPTCHA.addToken(data);
			}

			await submitSignup({ data, button, modal, form });
		} else {
			await submitSignup({ data, button, modal, form });
		}
	});

	modal.on('hidden.bs.modal', function() {
		if (config.user.logged) {
			modal.remove();
		} else {
			modal.find('form')[0].reset();
		}
	}).on('show.bs.modal', function(e) {
		if ($("#trialOrderFormModal").hasClass('in')){
			console.log("Showing new order modal so no register modal will be shown")
			return false;
		}
		if (config.currentAffiliate !== null && config.currentAffiliate.hasOwnProperty('signup')) {
			e.preventDefault();
			window.location = config.currentAffiliate.signup;
		}
	});

	if(config.route=='home' && window.location.hash=='#registrationModal'){
		modal.modal('show');
	}

}
