const domain = window.location.host.split('.').reverse()[1] === 'financhill' ?
	window.location.origin :
	'https://financhill.com';

var scoreWidgetSearchTimes = 0;
export default function scoreWidget() {

    scoreWidgetSearchTimes = 0


    $(".search-bar").submit((e)=>{
        e.preventDefault();
        //console.log(e)
        let symbol = $(".search-bar .search-box").val();


        $(".score-widget .title").html("Seaching "+symbol.toUpperCase()+"...")
        $(".search-bar input").prop("disabled", true)
        $(".score-widget").addClass("loading")



        let options = {
            url: "scores.json?t=widget&query="+symbol ,
            success: (response)=>{
                //console.log(response)

                let stock = response.length>0?response[0]:false
                if(stock){
                    scoreWidgetSearchTimes++
                    $(".search-bar .search-box").val("");
                    $(".score-widget .title").html(`${stock.name} (${stock.symbol}) is a ${stock.stock_score_signal}`)
                    $(".score-widget .watchlist-stock-score").html("<span>SCORE</span>"+stock.stock_score_normalized)
                    if(scoreWidgetSearchTimes==4){
                        $(".score-widget .watchlist-stock-score").html("<span>SCORE</span>?")
                        $(".score-widget button.cta2").hide()
                        $(".score-widget button.cta").show()
                        $(".search-bar").addClass("loading")
                        $(".search-bar input").prop("disabled", true);
                    }else{
                        $(".score-widget button.cta2").show()
                    }
                }else{
                    $(".score-widget .title").html("Couldn't find "+symbol)
                    $(".score-widget .watchlist-stock-score").html("<span>SCORE</span>?")
                }
                if(scoreWidgetSearchTimes<4){
                    $(".search-bar input").prop("disabled", false)
                }
                $(".score-widget").removeClass("loading")
                $(".search-bar .search-box").focus()

            },
            error: function(e) {
                console.error(e)
            }
        };
        $.ajax(options);

        return false
    })

    $(".score-widget .cta").click(()=>{

        $(".score-widget").hide()
        parent.resizeScoreWidget && parent.resizeScoreWidget()
        parent.closeScoreWidget && parent.closeScoreWidget()

    })
    $(".score-widget .close-btn").click(()=>{
        $(".score-widget").hide();
        parent.closeScoreWidget && parent.closeScoreWidget()
    })
    $(".score-widget .cta2").click(()=>{

        //$(".score-widget").hide()
        parent.cta2 && parent.cta2()

    })

};
