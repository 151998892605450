const changePasswordForm = $(".reset-password-form");

if(config.route !== 'reset-password' && changePasswordForm.length) {
	changePasswordForm.on('submit', function(e) {
		e.preventDefault();
		var form = $(this),
			btn = form.find('.btn-primary'),
			data = form.serializeJSON();

		if(form.data('submitting') === true){
			return;
		}

		form.data('submitting', true);
		btn.button('loading').prop('disabled', true);

		data.currentRoute = config.route;

		ajax('changePassword', data, function(r) {
			alert("Your password has been changed.");
			btn.button('reset').prop('disabled', false);
			form.data('submitting', false);
			form[0].reset();
			$("#resetPasswordModal").modal('hide');
		}, function() {
			btn.button('reset').prop('disabled', false);
			form.data('submitting', false);
		});
	});
}
