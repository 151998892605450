import Bloodhound from 'typeahead.js/dist/bloodhound.js';
import 'typeahead.js/dist/typeahead.jquery.js';

function strcmp(a, b) {
	a = a.toString(), b = b.toString();
	for (var i=0,n=Math.max(a.length, b.length); i<n && a.charAt(i) === b.charAt(i); ++i);
	if (i === n) return 0;
	return a.charAt(i) > b.charAt(i) ? -1 : 1;
}

let symbolAutoComplete = null;

class SymbolFormInstance {
	constructor(form) {
		this.form = form;
		this.input = this.form.querySelector('.symbol-form-input');
		this.format = this.form.dataset.urlFormat;
		this.trendingSymbols = this.form.parentElement.querySelectorAll('.trending-stocks a');

		this.modal = $(form).closest('.modal');

		this.attachEvents();
		this.attachAutoComplete();
	}

	attachEvents() {
		this.form.addEventListener('submit', e => {
			e.preventDefault();
			this.select({ symbol: this.input.value });
		});

		this.input.addEventListener('focus', () => {
			this.previousValue = this.input.value;
			this.input.value = '';
		});

		this.input.addEventListener('blur', () => {
			if (this.input.value === "") {
				this.input.value = this.previousValue;
			}
		});

		for (const link of this.trendingSymbols) {
			link.addEventListener('click', e => {
				e.preventDefault();
				this.select({ symbol: link.dataset.symbol });
			});
		}
	}

	select(symbol) {
		if (SymbolForm.callback) {
			SymbolForm.callback(symbol, this);
		} else {
			SymbolForm.defaultCallback(symbol, this);
		}

		SymbolForm.select(symbol);

		$(this.input).typeahead('close');
		this.input.blur();

		if (this.modal) {
			this.modal.modal('hide');
		}
	}

	attachAutoComplete() {
		$(this.input).typeahead({
			hint: true,
			highlight: true,
			minLength: 1
		},
		{
			name: 'symbols',
			source: symbolAutoComplete,
			limit: 10,
			display: 'symbol',
			templates: {
				suggestion: function(d) {
					return `
						<div class="symbol-form-suggestion">
							<strong class="symbol-form-suggestion-symbol">${d.symbol}</strong>
							<em class="symbol-form-suggestion-stock truncate">${d.name}</em>
						</div>`;
				}
			}
		}).on('typeahead:select', (e, value) => {
			this.select(value);
		});
	}
}

const SymbolForm = {
	forms: [],

	init(callback = null) {
		this.setCallback(callback);
		this.initAutoComplete();

		const forms = document.querySelectorAll('.symbol-form');

		for (const form of forms) {
			this.forms.push(new SymbolFormInstance(form));
		}
	},

	async select(stock) {
		await gtmEvent('search', { search_term: stock.symbol });

		for (const form of this.forms) {
			form.input.value = stock.symbol;
		}
	},

	setCallback(callback) {
		this.callback = callback;
	},

	defaultCallback(stock, form) {
		window.location = form.format
			.replace('[symbol]', stock.symbol.toLowerCase())
			.replace('[exchange]', stock.exchange ? stock.exchange.toLowerCase() : '');
	},

	initAutoComplete() {
		symbolAutoComplete = new Bloodhound({
			datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
			queryTokenizer: Bloodhound.tokenizers.whitespace,
			// prefetch: config.url.base + 'symbols.json',
			remote: {
				url: config.url.base + 'symbol.json?query=',
				prepare(query, settings) {
					settings.url += encodeURIComponent(query);

					return settings;
				}
			},
			sorter: function(a, b) {
				return strcmp(a.symbol, b.symbol);
			}
		});

		return symbolAutoComplete;
	},
}

export default SymbolForm;

export {
	symbolAutoComplete as autocomplete,
};
