let initialized = false;

const popoverWhitelist = $.fn.popover.Constructor.DEFAULTS.whiteList

popoverWhitelist['*'].push('data-target', 'data-toggle');

export default function() {
	if (initialized) {
		$('[data-toggle="tooltip"]').tooltip('destroy');
		$('[data-toggle="popover"]').popover('destroy');
	}

	$('[data-toggle="tooltip"]').tooltip({
		container: 'body',
		trigger: 'hover',
	});

	$('[data-toggle="popover"]').popover();

	initialized = true;
}
