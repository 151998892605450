import * as userSettings from './user-settings.js';

class Marquee {
	constructor(element) {
		this.getElements(element);

		if (!this.first || !this.second) {
			return;
		}

		this.initState();
		this.addEvents();
	}

	getElements(element) {
		this.element = element;

		this.first = element.querySelector('.marquee-first');
		this.second = element.querySelector('.marquee-second');

		this.pauseButton = element.querySelector('.marquee-pause-btn');

		this.key = element.dataset.key;
	}

	initState() {
		this.state = this.element.dataset.state;
	}

	addEvents() {
		if (this.pauseButton) {
			this.pauseButton.addEventListener('click', e => {
				e.preventDefault();

				this.handleClick();
			});
		}
	}

	get pauseState() {
		return this.state;
	}

	set pauseState(state) {
		this.state = state;

		this.element.classList.toggle('marquee-running', state === 'running');
		this.element.classList.toggle('marquee-paused', state === 'paused');
	}

	setState(state) {
		this.pauseState = state;

		userSettings.set('marquee_paused', state === 'paused' ? 1 : 0);
	}

	handleClick() {
		const newState = this.pauseState === 'paused' ? 'running' : 'paused';

		this.setState(newState);
	}
}

export default function() {
	const marquees = Array.from(
		document.getElementsByClassName('marquee'));

	if (!marquees.length) {
		return;
	}

	for (const marqueeEl of marquees) {
		const marquee = new Marquee(marqueeEl);
	}
}
