import jQuery from 'jquery';

import without from 'lodash/without.js';
import omit from 'lodash/omit.js';
import uniq from 'lodash/uniq.js';
import pick from 'lodash/pick.js';
import isArray from 'lodash/isArray.js';
import isObject from 'lodash/isObject.js';
import isEqual from 'lodash/isEqual.js';

const lodash = {
	without,
	omit,
	uniq,
	pick,
	isArray,
	isObject,
	isEqual,
};

window.$ = jQuery;
window.jQuery = jQuery;
window._ = lodash;
