class Countdown {
	constructor(element) {
		this.element = element;
		this.endTime = +element.dataset.time * 1000;

		this.days = element.querySelector('.countdown-days');
		this.hours = element.querySelector('.countdown-hours');
		this.minutes = element.querySelector('.countdown-minutes');
		this.seconds = element.querySelector('.countdown-seconds');
		this.milliseconds = element.querySelector('.countdown-milliseconds');

		this.interval = setInterval(() => this.decrement(), this.milliseconds ? 25 : 1000);

		this.bar = element.closest('.countdown-bar');
		this.closeBtn = this.bar?.querySelector('.countdown-bar-close-btn');

		if (this.closeBtn) {
			this.closeBtn.addEventListener('click', e => {
				e.preventDefault();
				this.hide();
			});
		}

		this.initialTimeLeft = this.endTime - (new Date());
	}

	end() {
		clearInterval(this.interval);
	}

	hide() {
		this.end();
		this.bar ? this.bar.remove() : this.element.remove();
	}

	decrement() {
		const timeLeft = this.endTime - (new Date());

		if (timeLeft <= 0) {
			if(this.element.dataset.reset) {
				this.endTime += this.initialTimeLeft;
				return;
			} else {
				this.end();

				if (this.days) {
					this.days.textContent = '00';
				}

				if (this.hours) {
					this.hours.textContent = '00';
				}

				if (this.minutes) {
					this.minutes.textContent = '00';
				}

				if (this.seconds) {
					this.seconds.textContent = '00';
				}

				if (this.milliseconds) {
					this.milliseconds.textContent = '00';
				}

				return;
			}
		}

		const days = Math.floor(timeLeft / 86400000);
		const hours = Math.floor((timeLeft - (days * 86400000)) / 3600000);
		const minutes = Math.floor((timeLeft - (days * 86400000) - (hours * 3600000 )) / 60000);
		const seconds = Math.floor((timeLeft - (days * 86400000) - (hours * 3600000) - (minutes * 60000))/1000);
		const milliseconds = Math.floor((timeLeft - (days * 86400000) - (hours * 3600000) - (minutes * 60000) - (seconds * 1000)) / 10);

		if (this.days) {
			this.days.textContent = this.padNumber(days);
		}

		if (this.hours) {
			this.hours.textContent = this.padNumber(hours);
		}

		if (this.minutes) {
			this.minutes.textContent = this.padNumber(minutes);
		}

		if (this.seconds) {
			this.seconds.textContent = this.padNumber(seconds);
		}

		if (this.milliseconds) {
			this.milliseconds.textContent = this.padNumber(milliseconds);
		}

		if (timeLeft <= 0) {
			clearInterval(this.interval);
			return;
		}
	}

	padNumber(number) {
		if (number < 10) {
			return `0${number}`;
		}

		return number;
	}
}

const countdowns = Array.from(document.querySelectorAll('.countdown'));

if (countdowns.length) {
	for (const countdown of countdowns) {
		new Countdown(countdown);
	}
}
