function importPage(value) {
	const page = pages[ value ] || value;

	if (pageCache[ page ]) {
		return pageCache[ page ];
	}

	return pageCache[ page ] = import(
		/* webpackChunkName: "[request]" */
		`./pages/${page}.js`
	);
}

async function loadPage(pageName, ... args) {
	if (!pages.hasOwnProperty(pageName)) {
		return;
	}

	let initPage;

	try {
		initPage = await importPage(pageName);
	} catch(e) {
		alert('The page could not be loaded; please reload and try again.: '+e);
		return;
	}

	try {
		await initPage.default(... args);
	} catch(err) {
		console.error(err);
	}
};

const pageCache = {

}

const pages = {
	'home': null,
	'error': 'home',
	'main': null,
	'chart': null,
	'calculators-dividends': 'stock-list',
	'value-investor-stocks': null,
	'economy': null,
	'fundamentals': null,
	'seasonality': null,
	'sentiment': null,
	'stock-score': null,
	'ai-charts':null,
	'patterns': null,
	'charts': null,
	'financials': null,
	'optin': null,
	'order': null,
	'products': null,
	'reports': null,

	'dashboard': 'stock-list',
	'stock-list': null,
	'screen-seasonality': null,
	'screen-stock-score': 'stock-list',
	'screen-book-value': 'stock-list',
	'screen-dividends': 'stock-list',
	'screen-valuation': 'stock-list',
	'screen-ai-charts': 'stock-list',
	'screen-covered-calls': null,
	'best-stocks-under': null,

	'labs-buyback': 'stock-list',
	'labs-growth': null,
	'labs-fcf-yield': 'stock-list',
	'labs-fcf-time': 'stock-list',

	'company-snapshot': null,
	'valuation': null,
	'watchlist': null,
	'billionaire-portfolios': null,
	'documents': null,
	'portfolio': null,
	'alerts-calendar': null,
	'education': null,
	'videos': null,

	'income-investor-plus': 'newsletters',
	'price-predictor': 'newsletters',
	'seasonality-trade-ideas': 'newsletters',
	'seasonality-trade-ideas-alerts': 'newsletters',
	'market-review': 'newsletters',
	'undervalued-buyback': 'newsletters',
	'market-news': 'newsletters',

	'top-gainers': 'stock-list',
	'biggest-losers': 'stock-list',
	'top-etf-gainers': 'stock-list',
	'worst-etf-losers': 'stock-list',
	'list-seasonality-trade-ideas': 'stock-list',

	'top-seasonal-stocks': null,

	'forgot-password': null,
	'gdpr': null,
	'landing': null,
	'login': null,
	'reset-password': null,
	'special-partners-register': null,
	'my-account': null,
	'unsubscribe': null,
	'referrals': null,
	'verify-email': null,
}

export default loadPage;

export {
	importPage,
	loadPage,
}
