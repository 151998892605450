import * as userSettings from './user-settings.js';

let firstInit = false;

const events = {
	open: new Event('toast.open'),
	close: new Event('toast.close'),
}

const Toasts = {
	init(container = document) {
		if (container instanceof jQuery) {
			container = container[0];
		}

		const toasts = Array.from(container.getElementsByClassName('toast'));

		if (!toasts.length) {
			return;
		}

		this.addDocumentListener();

		firstInit = true;

		for (const toast of toasts) {
			this.initToast(toast);
		}

		for (const { id, open, close } of callbacks) {
			const toast = document.getElementById(id);

			if (!toast) {
				continue;
			}

			if (open) {
				toast.addEventListener('toast.open', open);
			}

			if (close) {
				toast.addEventListener('toast.close', close);
			}
		}
	},

	initToast(toast) {
		toast.addEventListener('transitionend', e => {
			if (e.propertyName === 'opacity') {
				if (toast.classList.contains('in')) {
					toast.dispatchEvent(events.open);
				} else {
					toast.remove();
					toast.dispatchEvent(events.close);
				}
			}
		});
	},

	addDocumentListener() {
		if (firstInit) {
			return;
		}

		document.addEventListener('click', e => {
			const target = e.target.dataset?.dismiss ? e.target : e.target.closest('[data-dismiss]');

			if (!target) {
				return;
			}

			if (target.dataset?.dismiss !== 'toast') {
				return;
			}

			const toast = target.closest('.toast');

			toast.classList.remove('in');
		});
	},
}

const callbacks = [
	{
		id: 'toast-twitter',
		close() {
			userSettings.set('twitter_notification_hidden', 1);
		},
	}
];

export default Toasts;
