const $ = window.jQuery;

var config = window.config;
/* !Let's extend jQuery before we do anything else */
//extend jQuery
$.fn.extend({
	serializeJSON: function() {
		var data = {}, serialize = $(this).serializeArray(), i, tmp;
		for(i in serialize) {
			if(typeof serialize[i].name === 'undefined') {
				continue;
			}
			if((tmp = serialize[i].name.match(/^([^\[]+)\[([^\]]+)\]$/))) {
				if(!data[tmp[1]]) {
					data[tmp[1]] = {};
				}
				data[tmp[1]][tmp[2]] = serialize[i].value;
			} else {
				data[serialize[i].name] = serialize[i].value;
			}
		}
		return data;
	}
});

/* !AJAX-related stuff */
$.ajaxSetup({
	dataType: 'json',
	type: 'post'
});

var _ignoreAjaxError = false;

function ajaxSuccess(response, callback, err, noErr) {
	if(typeof response.messages !== 'undefined' && response.messages !== null) {
		if(typeof response.messages === 'object') {
			response.messages = response.messages.join('\n');
		}
		if(response.messages.length) {
			alert(response.messages);
		}
	}
	//Hook #1
	if(typeof window.$ajax !== 'undefined' && typeof window.$ajax.successStart === 'function') {
		window.$ajax.successStart.call(response);
	}
	if(!response.success) {
		if(response.auth) {
			ajaxAuth(response.auth, response.error);
			if(typeof err === 'function') {
				err();
			}
		} else if(response.hasOwnProperty('redirect')) {
			document.location.replace(config.url.base + response.redirect);
			return;
		} else {
			if(!noErr && response.error) {
				alert(response.error);
			}
			if(typeof err === 'function') {
				err.call(response, response);
			}
		}
	} else {
		if(typeof callback === 'function') {
			callback.call(response, response);
		}
	}
	//Hook #2
	if(typeof window.$ajax !== 'undefined' && typeof window.$ajax.successEnd === 'function') {
		window.$ajax.successEnd.call(response);
	}
}

function ajax(action, data, callback, err, noErr = false) {
	var options;

	if(typeof window.$ajax !== 'undefined' && typeof window.$ajax.data === 'function') {
		data = window.$ajax.data.call(data);
	}

	options = {
		url: config.url.base + action + '?_framework_ajax=1',
		data: data,
		complete(response) {
			if (typeof window.$ajax !== 'undefined' && typeof window.$ajax.complete === 'function') {
				window.$ajax.complete.call(response);
			}
		},
		success: function(response) {
			ajaxSuccess(response, callback, err, noErr);
		},
		error: function() {
			if(_ignoreAjaxError) {
				return;
			}

			if (!noErr) {
				alert("There was an error; please try again later.");
			}

			if(typeof err === 'function') {
				err();
			}
		}
	};

	if(typeof FormData !== 'undefined' && data instanceof FormData) {
		options.contentType = false;
		options.processData = false;
	}

	return $.ajax(options);
}

function ajaxAuth(key, message) {
	if(message === true) {
		return forceAuth();
	}

	var auth = prompt(message);
	if(auth === null || auth === undefined || auth === '') {
		return forceAuth();
	}
	ajax("_auth", {key: key, auth: auth});
}

function forceAuth() {
	$("body").empty();
	document.location.reload();
}

function loadImage(url) {
	var img = new Image();
	img.src = url;
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
window.numberWithCommas = numberWithCommas;

window.ajax = ajax;
window.ajaxSuccess = ajaxSuccess;
window.loadImage = loadImage;

window.onbeforeunload = function() {
	_ignoreAjaxError = true;
};