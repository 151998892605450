function activateDidYouMean(form, original_email, suggestion_email) {
	popup(
		`<p>You entered <strong class="text-accent">${original_email}</strong>.</p><p>Did you mean <strong class="text-accent">${suggestion_email}</strong>?</p>`,
		() => {
			form.find('[type="email"]').val(suggestion_email);
			handleSubmit(form, original_email, suggestion_email);
		},
		() => {
			handleSubmit(form, original_email, suggestion_email);
		}
	);
}

function handleSubmit(form, original_email, suggestion_email) {
	form.data('skip_email_check', original_email);
	form.trigger('submit');
}

export default activateDidYouMean;
