$(document).on('click submit', '[data-event-category]', async function(e) {
	if ($(this).is('form') && e.type=="click") {
		return true;
	}

	const data = $(this).data();

	const event = {
		... _.pick(data, [
			'eventCategory',
			'eventAction',
			'eventLabel',
			'eventValue',
		]),
		event: "logEvent",
	}

	await gtmEvent(event.eventAction, {
		label: event.eventLabel,
		category: event.eventCategory,
	});
});

// recommended events: https://support.google.com/analytics/answer/9267735

async function gtmEvent(name, data) {
	await new Promise(resolve => {
		const eventCallback = () => {
			console.log(`logged ${name} event successfully`);
			resolve();
		};

		const event = {
			event: name,

			label: null,
			category: null,
			items: null,
			value: null,
			currency: null,
			method: null,
			search_term: null,

			... data,

			eventCallback,
		};

		console.log({ event });

		if (window.dataLayer) {
			window.dataLayer.push(event);

			if (!window.dataLayer.find(row => row.event === 'gtm.load')) {
				eventCallback();
			}
		} else {
			eventCallback();
		}
	});
}

function ga4Cart(... items) {
	const value = items
		.map(item => +item.price)
		.reduce((accumulator, value) => accumulator + value, 0);

	return {
		currency: 'USD',
		value,
		items: items.map((item, index) => ({
			... item,
			index,
		})),
	};
}

window.gtmEvent = gtmEvent;
window.ga4Cart = ga4Cart;
