export default function wistiaLazyLoad() {
	$(document).on('click','a.wistia-lazy-load', function() {

		if( ! $('script#wistia-lazy-load-ev1').length ){
			let script = document.createElement('script');
		  script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
		  script.async = true;
			script.id = 'wistia-lazy-load-ev1';
		  document.head.appendChild(script); 
		}
		
		let videoWistiaId=$(this).data('video-wistia-id');
		if(! $('script#wistia-lazy-load-'+videoWistiaId).length ){
			let script = document.createElement('script');
		  script.src = 'https://fast.wistia.com/embed/medias/' + videoWistiaId+ '.jsonp'
		  script.async = true;	  
			script.id = 'wistia-lazy-load-'+videoWistiaId;
		  document.head.appendChild(script); 
		}
		
		window._wq = window._wq || [];

		window._wq.push({
	    id: videoWistiaId,
			onReady: function(video) {
	      var hash = window.location.hash;

	      if (window.location.hash === '#video') {
	        video.play();
	      }
	    }
	  });

	});

}
