const loginForm = $(".login-form");

if (loginForm.length) {
	loginForm.on('submit', function(e) {
		e.preventDefault();

		const url = new URL(location);
		const next = url.searchParams.has('next') ? `/${url.searchParams.get('next')}` : false;

		const form = $(this);
		const data = form.serializeJSON();

		data.currentRoute = config.route;

		form.find(':submit').button('loading');

		ajax('login', data, async function(r) {
			form.find(':submit').button('reset');

			try {
				await gtmEvent('login', { method: 'email' });
			} catch(e) {
				console.error(e);
			}

			if (next) {
				window.location = next;
			} else {
				window.location.reload();
			}
		}, function() {
			form.find(':submit').button('reset');
		});
	});
}

const loginModal = $("#loginModal");

loginModal.on('hidden.bs.modal', function() {
	loginModal.find('form')[0].reset();
});
