async function setSetting(name, value) {
	try {
		await ajax('setUserSetting', { name, value });
	} catch {}
}

async function getSetting(name) {
	return await ajax('getUserSetting', { name });
}

export {
	getSetting as get,
	setSetting as set,
}
